/* Carousel.css */
.carousel-container {
    position: relative;
    width: 100%;
}

.carousel-inner {
    position: relative;
    height: 70rem; /* Adjust as needed */
    overflow: hidden;
    background-color: black;
    border-radius: 0.5rem; /* Adjust as needed */
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.7s ease-in-out;
}

    .carousel-item.hidden {
        display: none;
    }

    .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

.carousel-indicators {
    position: absolute;
    bottom: 1.25rem; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.75rem; /* Adjust as needed */
}

.carousel-indicator {
    width: 0.75rem; /* Adjust as needed */
    height: 0.75rem; /* Adjust as needed */
    border-radius: 50%;
    background-color: #d1d5db; /* Gray 300 */
}

    .carousel-indicator.active {
        background-color: #3b82f6; /* Blue 500 */
    }

.carousel-control {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem; /* Adjust as needed */
    height: 2.5rem; /* Adjust as needed */
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transition: background-color 0.3s;
}

    .carousel-control:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .carousel-control svg {
        width: 1rem; /* Adjust as needed */
        height: 1rem; /* Adjust as needed */
    }

.carousel-prev {
    left: 0;
}

.carousel-next {
    right: 0;
}
